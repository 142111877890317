"use client";
import React, { createContext, useContext, useState } from "react";

import {
  useQueryState,
  parseAsJson,
  createParser,
  parseAsInteger,
  type Options,
} from "nuqs";
import { z } from "zod";

import { type IOutreachFilters } from "./IOutreachFilters";

interface OutreachFiltersContextType {
  filters: IOutreachFilters | null;
  setFilters: (
    value:
      | IOutreachFilters
      | null
      | ((prev: IOutreachFilters | null) => IOutreachFilters | null),
    options?: { shallow?: boolean }
  ) => Promise<URLSearchParams>;
  page: number;
  setPage: (
    value: number | ((old: number) => number | null) | null,
    options?: Options | undefined
  ) => Promise<URLSearchParams>;
  isInitialStale: boolean;
  setIsInitialStale: React.Dispatch<React.SetStateAction<boolean>>;
  sortedColumn: { column?: string; sort?: string } | null;
  setSortedColumn: (
    value:
      | { column?: string; sort?: string }
      | null
      | ((
          prev: { column?: string; sort?: string } | null
        ) => { column?: string; sort?: string } | null),
    options?: { shallow?: boolean }
  ) => Promise<URLSearchParams>;
  prevOutreachId: string | null;
  setPrevOutreachId: React.Dispatch<React.SetStateAction<string | null>>;
}

const OutreachFiltersContext = createContext<OutreachFiltersContextType | null>(
  null
);

export function useOutreachFiltersContext() {
  const context = useContext(OutreachFiltersContext);
  if (!context) {
    throw new Error(
      "useOutreachFilters must be used within an OutreachFiltersProvider"
    );
  }
  return context;
}

const sortedColumnSchema = z.object({
  sort: z.string().optional(),
  column: z.string().optional(),
});

const filterObjToString = (f: IOutreachFilters) =>
  JSON.stringify(f, (_, value: IOutreachFilters[keyof IOutreachFilters]) => {
    if (value instanceof Date) {
      return value.toISOString();
    }
    return value;
  });

const parseFilters = createParser<IOutreachFilters>({
  parse(queryValue) {
    return JSON.parse(queryValue, (key, value: unknown) => {
      if (typeof value === "string" && !isNaN(Date.parse(value))) {
        return new Date(value);
      }
      return value;
    }) as IOutreachFilters;
  },
  serialize(objValue) {
    return filterObjToString(objValue);
  },
  eq: (a, b) => filterObjToString(a) === filterObjToString(b),
});

export const DEFAULT_FILTERS: IOutreachFilters = {
  status: ["IN_PROGRESS", "PENDING", "FAILED", "COMPLETED"],
};

export function OutreachFiltersProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [filters, setFilters] = useQueryState(
    "filters",
    parseFilters.withDefault(DEFAULT_FILTERS)
  );
  const [page, setPage] = useQueryState<number>(
    "page",
    parseAsInteger.withDefault(1)
  );
  const [isInitialStale, setIsInitialStale] = useState<boolean>(false);
  const [sortedColumn, setSortedColumn] = useQueryState<{
    column?: string;
    sort?: string;
    // eslint-disable-next-line @typescript-eslint/unbound-method
  }>("sortedColumn", parseAsJson(sortedColumnSchema.parse));
  const [prevOutreachId, setPrevOutreachId] = useState<string | null>(null);

  return (
    <OutreachFiltersContext.Provider
      value={{
        filters,
        setFilters: setFilters,
        setIsInitialStale,
        isInitialStale,
        page,
        setPage,
        setSortedColumn,
        sortedColumn,
        prevOutreachId,
        setPrevOutreachId,
      }}
    >
      {children}
    </OutreachFiltersContext.Provider>
  );
}
