"use client";
import React, { createContext, use, useContext } from "react";

import { usePathname } from "next/navigation";
import { useQueryState, parseAsJson } from "nuqs";
import { z } from "zod";

import { api } from "@web/trpc/client";
import { useArray } from "@web/utils";

import { type Campaign } from "./ICampaign";

type CampaignsPath = {
  pathname?: string;
  campaignId?: string;
  outreachGroupId?: string;
  outreachId?: string;
};
export interface ICampaignContext {
  campaigns: Campaign[];
  setCampaigns: React.Dispatch<React.SetStateAction<Campaign[]>>;
  campaignsPath: CampaignsPath;
  sortedCampaignColumn: { column?: string; sort?: string } | null;
  setSortedCampaignColumn: (
    value:
      | { column?: string; sort?: string }
      | null
      | ((
          prev: { column?: string; sort?: string } | null
        ) => { column?: string; sort?: string } | null),
    options?: { shallow?: boolean }
  ) => Promise<URLSearchParams>;

  sortedOutreachGroupColumn: { column?: string; sort?: string } | null;
  setSortedOutreachGroupColumn: (
    value:
      | { column?: string; sort?: string }
      | null
      | ((
          prev: { column?: string; sort?: string } | null
        ) => { column?: string; sort?: string } | null),
    options?: { shallow?: boolean }
  ) => Promise<URLSearchParams>;
}

const CampaignContext = createContext<ICampaignContext | null>(null);

export function useCampaignContext() {
  const campaignContext = useContext(CampaignContext);

  if (!campaignContext) {
    throw new Error(
      "useCampaignContext must be used within a CampaignProvider"
    );
  }

  return campaignContext;
}

const sortedColumnSchema = z.object({
  sort: z.string().optional(),
  column: z.string().optional(),
});

const CampaignProvider: React.FC<{
  children: React.ReactNode;
  initialCampaigns: Promise<Campaign[]>;
}> = ({ children, initialCampaigns }) => {
  const [sortedCampaignColumn, setSortedCampaignColumn] = useQueryState<{
    column?: string;
    sort?: string;
  }>(
    "sortedCampaignColumn",
    // eslint-disable-next-line @typescript-eslint/unbound-method
    parseAsJson(sortedColumnSchema.parse)
  );
  const [sortedOutreachGroupColumn, setSortedOutreachGroupColumn] =
    useQueryState<{
      column?: string;
      sort?: string;
    }>(
      "sortedOutreachGroupColumn",
      // eslint-disable-next-line @typescript-eslint/unbound-method
      parseAsJson(sortedColumnSchema.parse)
    );
  const { data } = api.campaign.getStoreCampaigns.useQuery(undefined, {
    initialData: use(initialCampaigns),
  });
  const { value: campaigns, setValue: setCampaigns } = useArray<Campaign>(data);
  const pathname = usePathname();
  const campaignsPath: CampaignsPath = React.useMemo(() => {
    return pathname.startsWith("/campaigns")
      ? {
          pathname,
          campaignId: pathname.split("/")[2] ?? undefined,
          outreachGroupId: pathname.split("/")[3] ?? undefined,
          outreachId: pathname.split("/")[4] ?? undefined,
        }
      : {
          pathname: undefined,
          campaignId: undefined,
          outreachGroupId: undefined,
          outreachId: undefined,
        };
  }, [pathname]);
  const campaignContextValue: ICampaignContext = {
    campaigns: campaigns,
    setCampaigns: setCampaigns,
    campaignsPath: campaignsPath,
    sortedCampaignColumn: sortedCampaignColumn,
    setSortedCampaignColumn: setSortedCampaignColumn,
    setSortedOutreachGroupColumn: setSortedOutreachGroupColumn,
    sortedOutreachGroupColumn: sortedOutreachGroupColumn,
  };
  return (
    <CampaignContext.Provider value={campaignContextValue}>
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignProvider;
